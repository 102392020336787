<!-- RTO Damage 显示QC不合格 列表 -->
<template>
  <div 
	  v-loading.fullscreen="uploadloading"
    element-loading-text=""
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)" 
		class>
		<div class="listHeader">
			<i></i><span>{{$t('rtoDamagePage.pageTitle')}}</span>
		</div>
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="auto"
      style="margin:10px 0 0 20px">
      <el-form-item label>
        <el-input type="textarea" v-model="productCodeString" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.clientProductCode')" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleQuery">{{$t('commonInfo.searchBtn')}}</el-button>
				<el-button icon="el-icon-refresh" @click="handleRefresh">{{$t('commonInfo.refreshBtn')}}</el-button>
      </el-form-item>
    </el-form>

    <!-- 展示 -->
    <el-table style="margin:0 15px;width: calc(100% - 30px);font-size: 16px;" v-loading="loading" :data="dataList" border>
      <!-- <el-table-column type="index" width="50" align="center" /> -->
      <el-table-column prop="clientProductCode" :label="$t('commonInfo.clientProductCode')" align="center"></el-table-column>
			<el-table-column prop="productCode" :label="$t('commonInfo.productCode')" align="center"></el-table-column>
			<el-table-column prop="productNm" :label="$t('commonInfo.productName')" align="center"></el-table-column>
			<el-table-column prop="productQuantity" :label="$t('commonInfo.productQuantity')" align="center"></el-table-column>
			<!-- <el-table-column prop="appointmentQuantity" label="预约数量" align="center"></el-table-column> -->
    </el-table>
		<div class="pageBottom">
		  <el-pagination
		    @size-change="handleSizeChange"
		    @current-change="handleCurrentChange"
		    :current-page="queryParams.page"
		    :page-sizes="[10, 20, 30, 40]"
		    :page-size="queryParams.limit"
		    layout="total, sizes, prev, pager, next, jumper"
		    :total="total"
		  ></el-pagination>
		</div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
	import {
		formatDate
	} from "@/utils/index";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      queryParams: {
        page: 1,
        limit: 10,
				companyProductCodeList:[],//客户商品编码
      },
			productCodeString:'',
			loading:false,
			uploadloading:false,
      total: 0,
			dataList:[],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
	},
  //方法集合
  methods: {
		// 分页条数
		handleSizeChange(size) {
		  this.queryParams.limit = size;
		  this.queryParams.page = 1;
		  this.getList();
		},
		// 分页页数
		handleCurrentChange(page) {
		  this.queryParams.page = page;
		  this.getList();
		},
    async getList() {
			this.loading = true;
			this.dataList = [];
			const res = await this.$http.post("toborder/customer/rto/damage/product", this.queryParams);
			
			if (res.code === 200) {
			  let data = res.data.list;
			  // 总共有多少条数据，分页
			  this.total = res.data.totalCount;
				data.forEach(item => {
					item.initTime = formatDate(item.initTime);
				})
			  this.loading = false;
			  this.dataList = data;
			} else {
			  this.loading = false;
			  this.$message.error(res.msg);
			}
		},
		handleQuery(){
			this.queryParams.page = 1;
			if(this.productCodeString != ''){
				this.queryParams.companyProductCodeList = this.strToArr(this.productCodeString);
			}else{
				this.queryParams.companyProductCodeList = [];
			}
			this.getList();
		},
		handleRefresh(){
			this.productCodeString = '';
			this.queryParams = {
				page:1,
				limit:10,
				companyProductCodeList:[],//客户商品编码
			}
			this.getList();
		},
  },
  created() {
		this.getList();
	},
  mounted() {}
};
</script>
<style scoped>
	.listHeader{
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}
	.listHeader i{
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	.listHeader span{
		display: inline-block;
		float: left;
	}
	.pageBottom {
	  padding: 20px;
	}
</style>